<template>
  <b-container v-if="!isLoading" fluid>
    <AnalyticsHeader
      headerTitle="SHIPMENT DASHBOARD"
      @onDateChange="updateDate"
      @extractAnalysis="extractAnalysis"
    />
    <AnalyticsIcons :iconData="iconData" :itemsPerRow="3" />
    <!-- <Chart v-show="filterBy === 'monthly'" :selectedFilterBy="filterBy" :chartOptions="chartOptionsMonthly"
      @filterGraphBy="filterGraphBy" />
    <Chart v-show="filterBy === 'daily'" :selectedFilterBy="filterBy" :chartOptions="chartOptionsDaily"
      @filterGraphBy="filterGraphBy" /> -->
  </b-container>
</template>

<script>
import AnalyticsHeader from "./components/AnalyticsHeader.vue";
import AnalyticsIcons from "./components/AnalyticsIcons.vue";
import Chart from "../../../components/Chart.vue";
import exportFromJSON from "export-from-json";

export default {
  components: {
    AnalyticsHeader,
    AnalyticsIcons,
    Chart,
  },

  async created() {
    this.getAnalyticsData();
    this.isLoading = false;
  },

  methods: {
    updateDate(date, type) {
      if (type === "FROM") {
        this.fromDate = date;
      } else {
        this.toDate = date;
      }
      this.getAnalyticsData();
    },

    getAnalyticsData() {
      this.$http
        .get(`analytics/shipment/${this.fromDate}/${this.toDate}`)
        .then((response) => {
          if (response.data.status) {
            this.chartData = response.data.data;
            this.getCountData();

            //UPDATE MONTHLY CHART
            // this.chartOptionsMonthly = {
            //   ...this.chartOptionsMonthly,
            //   xAxisData: this.$helpers.getMonths(this.fromDate, this.fromDate),
            // };

            // const { firstMonth, lastMonth } = this.$helpers.getFirstAndLastMonths(
            //   this.chartOptionsMonthly.xAxisData
            // );

            // this.chartOptionsMonthly.series.forEach((series, i) => {
            //   switch (i) {
            //     case 0:
            //       series.data = this.chartData.ondemand.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 1:
            //       series.data = this.chartData.ondemand.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 2:
            //       series.data = this.chartData.distributor.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 3:
            //       series.data = this.chartData.Franchise.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 4:
            //       series.data = this.chartData.singapore.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     case 5:
            //       series.data = this.chartData.dhl.monthly.slice(
            //         firstMonth,
            //         lastMonth
            //       );
            //       break;
            //     default:
            //       break;
            //   }
            // });

            //UPDATE DAILY CHART
            // this.chartOptionsDaily = {
            //   ...this.chartOptionsDaily,
            //   xAxisData: this.$helpers.getDays(this.fromDate, this.fromDate),
            // };

            // const { firstDay, lastDay } = this.$helpers.getFirstAndLastDays(
            //   this.chartOptionsDaily.xAxisData
            // );

            // this.chartOptionsDaily.series.forEach((series, i) => {
            //   switch (i) {
            //     case 0:
            //       series.data = this.chartData.ondemand.daily.slice(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 1:
            //       series.data = this.chartData.distributor.daily.slice(firstDay, lastDay);
            //       break;
            //     case 2:
            //       series.data = this.chartData.Franchise.daily.slice(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 3:
            //       series.data = this.chartData.singapore.daily.slice(
            //         firstDay,
            //         lastDay
            //       );
            //       break;
            //     case 4:
            //       series.data = this.chartData.dhl.daily.slice(firstDay, lastDay);
            //       break;
            //     default:
            //       break;
            //   }
            // });
          } else {
            this.$swal({
              title: "Error !",
              icon: "warning",
              text: response.data.message,
              confirmButtonText: "OK",
              customClass: {
                confirmButton: "btn btn-danger",
              },
              buttonsStyling: false,
            });
          }
        });
    },

    getCountData() {
      this.iconData.forEach((item, i) => {
        switch (i) {
          case 0:
            item.value = 0;
            break;
          case 1:
            item.value = this.chartData.ondemand.count;
            break;
          case 2:
            item.value = this.chartData.distributor.count;
            break;
          case 3:
            item.value = this.chartData.Franchise.count;
            break;
          case 4:
            item.value = this.chartData.singapore.count;
            break;
          case 5:
            item.value = this.chartData.dhl.count;
            break;
          default:
            break;
        }
      });
    },

    async extractAnalysis() {
      const response = await this.$http.get(
        `analytics_report/shipment/${this.fromDate}/${this.toDate}`,
        { responseType: "blob" }
      );
      var fileURL = window.URL.createObjectURL(new Blob([response.data]));
      var fileLink = document.createElement("a");

      fileLink.href = fileURL;
      fileLink.setAttribute(
        "download",
        `ShipmentAnalytics_${this.fromDate}-${this.toDate}.xlsx`
      );
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    filterGraphBy(filter) {
      this.filterBy = filter;
    },
  },

  data() {
    return {
      isLoading: true,
      fromDate: new Date(
        Date.now() - 5 * 24 * 60 * 60 * 1000
      ).toLocaleDateString("en-CA"),
      toDate: new Date().toLocaleDateString("en-CA"),
      filterBy: "daily",
      exportType: "CSV",
      chartData: {},
      iconData: [
        {
          variant: "pink",
          icon: "HomeIcon",
          value: 0,
          label: "B2BPro",
        },
        {
          variant: "yellow",
          icon: "ShoppingCartIcon",
          value: 0,
          label: "Ondemand",
        },
        {
          variant: "beige",
          icon: "ShoppingBagIcon",
          value: 0,
          label: "Distributor",
        },
        {
          variant: "light-blue",
          icon: "BriefcaseIcon",
          value: 0,
          label: "Franchise",
        },
        {
          variant: "light-pink",
          icon: "FlagIcon",
          value: 0,
          label: "Singapore",
        },
        {
          variant: "light-beige",
          icon: "TruckIcon",
          value: 0,
          label: "DHL",
        },
      ],
      dailyXAxis: [],
      monthlyXAxis: [],
      chartOptionsDaily: {
        legend: {
          left: "left",
          data: [
            "B2BPro",
            "B2C",
            "Ondemand",
            "Distributor",
            "Franchise",
            "Singapore",
            "DHL",
          ],
        },
        xAxisData: [],
        series: [
          {
            name: "B2BPro",
            type: "line",
            data: [],
            itemStyle: { color: "#f6dade" },
          },
          {
            name: "B2C",
            type: "line",
            data: [],
            itemStyle: { color: "#f4e2d4" },
          },
          {
            name: "Franchise",
            type: "line",
            data: [],
            itemStyle: { color: "#3498db" },
          },
          {
            name: "Singapore",
            type: "line",
            data: [],
            itemStyle: { color: "#f2d6f6" },
          },
          {
            name: "DHL",
            type: "line",
            data: [],
            itemStyle: { color: "#f2d6f6" },
          },
        ],
      },
      chartOptionsMonthly: {
        legend: {
          left: "left",
          data: [
            "B2BPro",
            "Ondemand",
            "Distributor",
            "Franchise",
            "Singapore",
            "DHL",
          ],
        },
        xAxisData: [],
        series: [
          {
            name: "B2BPro",
            type: "line",
            data: [],
            itemStyle: { color: "#f6dade" },
          },
          {
            name: "B2C",
            type: "line",
            data: [],
            itemStyle: { color: "#f4e2d4" },
          },
          {
            name: "Franchise",
            type: "line",
            data: [],
            itemStyle: { color: "#3498db" },
          },
          {
            name: "Singapore",
            type: "line",
            data: [],
            itemStyle: { color: "#f2d6f6" },
          },
          {
            name: "DHL",
            type: "line",
            data: [],
            itemStyle: { color: "#f2d6f6" },
          },
        ],
      },
    };
  },
};
</script>

<style></style>
